import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { costDifference } from 'algostrata';
import { renderKr, renderPercent } from 'monax-rendering';
import render from '../lib/rendering';
import Contact from './Contact';
import CurrencyInputComponent from './InputComponents/CurrencyInputComponent';

function Calculator({ site }) {
    const [amount, setAmount] = useState(site.settings.defaultValue);
    const [strategyIndex, setStrategyIndex] = useState(0);
    const [result, setResult] = useState();

    useEffect(() => {
        const run = async () => {
            if (!site || !amount || strategyIndex === undefined) return;
            const strategy = site.settings.strategies[strategyIndex];
            setResult(costDifference(Number(amount), Number(strategy.avgAaop), Number(strategy.avgManagementCost), Number(strategy.newAaop), Number(strategy.newManagementCost)));
        };
        run();
    }, [site, amount, strategyIndex, setResult, setAmount, setStrategyIndex]);

    if (!result) return null;

    const strategyName = site.settings.strategies[strategyIndex].name;
    const strategyManagementCostPercent = renderPercent(site.settings.strategies[strategyIndex].newManagementCost);
    const amountKr = renderKr(amount);
    const savedKr = renderKr(result.totalSaved);
    const totalBaseCostKr = renderKr(result.totalBaseCost);

    return (
        <div className="oba-container">
            <div className="oba-input">
                <div>
                    <h4>{ site.settings.texts.amountTitle }</h4>
                    <div className="oba-input-amount">
                        <div>
                            <CurrencyInputComponent
                                name="amountInput"
                                value={ amount }
                                min={ site.settings.minimumValue }
                                max={ site.settings.maximumValue }
                                onChange={ setAmount }
                            />
                        </div>
                        <a className="help"><img src="./static/gfx/helpgrey.svg" /></a>
                        <div className="helpBubble">
                            { site.settings.texts.amountHelpText }
                        </div>
                    </div>
                </div>

                <div>
                    <h4>{ site.settings.texts.strategyTitle }</h4>
                    <div className="oba-input-strategy">
                        <div title={ site.settings.texts.strategyHelpText ? site.settings.texts.strategyHelpText : null }>
                            { site.settings.strategies.map((s, index) => (
                                <React.Fragment key={ index }>
                                    <input
                                        type="radio"
                                        name="strategy"
                                        id={ `strategy${index}` }
                                        value={ index } checked={ strategyIndex === index }
                                        onChange={ () => setStrategyIndex(index) } />
                                    <label htmlFor={ `strategy${index}` }>{ s.name }</label>
                                </React.Fragment>
                            )) }
                        </div>
                        <a className="help"><img src="./static/gfx/helpgrey.svg" /></a>
                        <div className="helpBubble">
                            { site.settings.texts.strategyHelpText }
                        </div>
                    </div>
                </div>
            </div>

            <div className="oba-result">
                <div>
                    <div className="oba-result-market">
                        <h3>
                            <span>Markedspris</span>
                            <span>{ result ? renderKr(result.totalBaseCost) : '0 kr.' }</span>
                        </h3>
                        <h5>
                            <span>Gennemsnitlig markedspris</span>
                            <span>* { result ? renderPercent(result.totalBaseCostFraction) : '0 %' }</span>
                        </h5>
                    </div>

                    <div className="oba-result-partner">
                        <h3>
                            <span>Vores pris</span>
                            <span>{ result ? renderKr(result.totalNewCost) : '0 kr.' }</span>
                        </h3>
                        <h5>
                            <span>Honorar + gns. omk. til fonde </span>
                            <span>* { result ? renderPercent(result.totalNewCostFraction) : '0 %' }</span>
                        </h5>
                    </div>

                    <div className="oba-result-saved">
                        <h2>
                            <span>Du sparer { result ? renderKr(result.totalSaved) : 0 }</span>
                            <span>I årlige omkostninger</span>
                        </h2>
                        <div />
                    </div>
                </div>

                <div>
                    <div className="oba-result-information">
                        <div>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: render(
                                        site.settings.texts.resultInformation,
                                        { strategyName, strategyManagementCostPercent, amountKr, savedKr, totalBaseCostKr },
                                    ),
                                }}
                            />
                            <div style={{ display: 'flex' }}>
                                <span />
                                <a href={ site.settings.urls.calculationInfoUrl } target='_blank' rel='noreferrer'>{ site.settings.texts.calculationInfoUrlText }</a>
                            </div>
                        </div>
                    </div>

                    <Contact
                        site={ site }
                        amount={ amount }
                        strategyIndex={ strategyIndex }
                        result={ result }
                    />
                </div>
            </div>

            <div className="oba-footer">
                Du er også velkommen til at kontakte os direkte på <a href={ `mailto:${site.settings.texts.email}` }>{ site.settings.texts.email }</a> eller telefon <a href={ `tel:${site.settings.texts.phone}` }>{ site.settings.texts.phone }</a>.
            </div>
        </div>
    );
}

Calculator.propTypes = {
    site: PropTypes.object.isRequired,
};

export default Calculator;
