import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import submitData from '../lib/submit';

function Contact({ amount, result, site, strategyIndex }) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [comment, setComment] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [submitStatus, setSubmitStatus] = useState();
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const termsCheckboxRef = useRef();

    const submit = async () => {
        if (!termsAccepted) {
            // eslint-disable-next-line no-alert
            alert('Du skal acceptere betingelserne før vi kan kontakte dig.');
            termsCheckboxRef.current.focus();
            return;
        }

        const data = { name, email, phone, comment, postalCode, amount, result, strategy: site.settings.strategies[strategyIndex] };

        setSubmitted(true);
        const response = await submitData(site, data);

        setSubmitStatus(response);
        setSubmitted(false);
    };

    const submitDisabled = submitted || !result ||
        (name === '') ||
        (email === '' && site.settings.contact.email === 'mandatory') ||
        (phone === '' && site.settings.contact.phone === 'mandatory') ||
        (comment === '' && site.settings.contact.comment === 'mandatory') ||
        (postalCode === '' && site.settings.contact.postalCode === 'mandatory');

    return (
        <div className="oba-result-contact">
            { ((submitStatus?.status !== 'ok' || submitStatus?.error) && (
                <>
                    <h2>Jeg vil gerne kontaktes</h2>
                    <div>
                        <div>
                            <label htmlFor="name">Dit navn *</label>
                            <input type="text" id="name" value={ name } onChange={ (e) => setName(e.target.value) } />
                        </div>
                        { site?.settings?.contact?.phone !== 'hidden' &&
                            <div>
                                <label htmlFor="phone">Telefon{ site?.settings?.contact?.phone === 'mandatory' && ' *' }</label>
                                <input type="phone" id="phone" value={ phone } onChange={ (e) => setPhone(e.target.value) } />
                            </div>
                        }
                        { site?.settings?.contact?.email !== 'hidden' &&
                            <div>
                                <label htmlFor="email">E-mail{ site?.settings?.contact?.email === 'mandatory' && ' *' }</label>
                                <input type="email" id="email" value={ email } onChange={ (e) => setEmail(e.target.value) } />
                            </div>
                        }
                        { site?.settings?.contact?.postalCode !== 'hidden' &&
                            <div>
                                <label htmlFor="postalcode">Postnummer{ site?.settings?.contact?.postalCode === 'mandatory' && ' *' }</label>
                                <input type="text" id="postalcode" value={ postalCode } onChange={ (e) => setPostalCode(e.target.value) } />
                            </div>
                        }
                        { site?.settings?.contact?.comment !== 'hidden' &&
                            <div className="comment">
                                <label htmlFor="comment">Kommentar{ site?.settings?.contact?.comment === 'mandatory' && ' *' }</label>
                                <textarea placeholder="Skriv evt. en kort besked til os" id="comment" value={ comment } onChange={ (e) => setComment(e.target.value) }></textarea>
                            </div>
                        }
                        <div className="terms">
                            <input type="checkbox" ref={ termsCheckboxRef } checked={ termsAccepted } id="terms" onChange={ () => setTermsAccepted(!termsAccepted) } />
                            <label htmlFor="terms">Ja tak, kontakt mig for en uforpligtende drøftelse om mine muligheder</label>
                        </div>

                        <div>
                            <div>
                                <button
                                    type="submit"
                                    disabled={ submitDisabled }
                                    onClick={ submit }
                                >
                                    { submitted ? 'Sender kontaktinfo..' : 'Kontakt mig' }
                                </button>
                            </div>

                            { submitStatus?.error &&
                                <p className="error">Der opstod en fejl ved afsendelse. Prøv venligst igen.</p>
                            }

                            <a href={ site.settings.urls.privacyUrl } target='_blank' rel='noreferrer'>Vi passer godt på dine data. Læse mere om GDPR og vores fortrolighedspolitik.</a>
                        </div>
                    </div>
                </>
            )) || (submitStatus?.status === 'ok' && (
                <>
                    <h2>Tak for din henvendelse!</h2>
                    <div className='receipt'>
                        <p>
                            Vi bestræber os på at kontakte dig inden 24 timer.<br/><br/>
                            Mvh.<br/>
                            { site.settings.texts.companyName }
                        </p>
                    </div>
                </>
            )) }
        </div>
    );
}

Contact.propTypes = {
    site: PropTypes.object.isRequired,
    amount: PropTypes.number,
    strategyIndex: PropTypes.number,
    result: PropTypes.object,
};

export default Contact;
